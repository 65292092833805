/* Overflow */
.overflow-hidden,
.overflow-none {
    overflow: hidden !important
}

.overflowX-hidden,
.overflowX-none {
    overflow-x: hidden !important
}

.overflowY-hidden,
.overflowY-none {
    overflow-y: hidden !important
}

.overflow {
    overflow: auto
}

.overflowX {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.overflowY {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.pointerEvents-none {
    pointer-events: none !important;
}

.pointerEvents-all {
    pointer-events: all !important;
}

/** Image */
.objectFit-cover {
    object-fit: cover !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-0 {
    border-radius: 0px !important;
}

/** Width */
.w-100p {
    width: 100% !important;
}

.w-50p {
    width: 50% !important;
}

.w-50px {
    width: 50px !important;
}

.max-w-246px {
    max-width: 246px !important;
}

.w-150px {
    width: 150px !important;
}

.w-150px {
    width: 150px !important;
}

.w-160px {
    width: 160px !important;
}

/** Height */
.h-100p {
    height: 100% !important;
}

.h-50p {
    height: 50% !important;
}

.h-50px {
    height: 50px !important;
}

.h-100px {
    height: 100px !important;
}

/** Border */
.b-none {
    border: none !important;
}

.b-0 {
    border: 0 !important;
}

.HW-20 {
    height: 20px !important;
    width: 20px !important;
}

/** Opacity */
// Mixin for generating opacity classes
@mixin opacity-class($value, $suffix: null) {
    .opacity-#{$suffix} {
        opacity: $value !important;
    }
}

// Generate opacity classes dynamically
@include opacity-class(0.10, "01");
@include opacity-class(0.20, "02");
@include opacity-class(0.25, "25");
@include opacity-class(0.30, "03");
@include opacity-class(0.40, "04");
@include opacity-class(0.50, "05");
@include opacity-class(0.50, "50"); // Alias for 50%
@include opacity-class(0.60, "06");
@include opacity-class(0.70, "07");
@include opacity-class(0.75, "75");
@include opacity-class(0.80, "08");
@include opacity-class(0.90, "09");
@include opacity-class(1, "1");
@include opacity-class(1, "100"); // Alias for 100%


// Common mixin for disabling interactions
@mixin disabled-box($opacity: null) {
    -webkit-user-select: none !important; // Prevent text selection on webkit browsers
    user-select: none !important;         // Prevent text selection on other browsers
    pointer-events: none !important;      // Disable all interactions
    @if $opacity != null {
        opacity: $opacity !important;     // Set opacity if provided
    }
}

// Base disabled box style
.disabled-box {
    @include disabled-box();
}

// Modifier classes for different opacity levels
.disabled-box-opacity-25 {
    @include disabled-box(0.25); // 25% opacity
}

.disabled-box-opacity-50 {
    @include disabled-box(0.50); // 50% opacity
}

.disabled-box-opacity-75 {
    @include disabled-box(0.75); // 75% opacity
}